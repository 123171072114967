























.foot{
	font-size: 14px;
	color: #909399;
  text-align: center;
}
