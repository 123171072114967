













































































































































































.counseling {
  z-index: 999;
  right: 28px;
  position: fixed;
  width: 56px;
  height: 344px;
  bottom: 40px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #dce0e4;
}
.all {
  width: 56px;
  height: 344px;
  font-family: SF Pro Display-Bold, SF Pro Display;
  font-weight: bold;
  color: #1b212e;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
}

.cls-sm {
  font-family: PingFang SC-Medium, PingFang SC;
  color: #303a50;
  right: 40px;
  // z-index: -2;
  display: inline-block;
  width: 56px;
  height: 72px;
  background: #ffffff;
  // opacity: 1;
  position: relative;
  text-align: center;
  font-size: 12px;
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
  .cls-out::before,
  .cls-out2::before {
    content: "";
    position: absolute;
    right: -30px;
    width: 30px;
    height: 100%;
  }
  &:hover .cls-out {
    display: block;
  }

  &:hover .cls-out2 {
    display: block;
  }
}

.cls-sms {
  right: 40px;
  display: inline-block;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  position: relative;
  text-align: center;
  font-size: 16px;
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
}
.cls-out {
  display: none;
  width: 180px;
  height: 154px;
  position: relative;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #dce0e4;
  float: right;
  bottom: 110px;
  right: 70px;
  font-family: SF Pro Display-Bold, SF Pro Display;
}

.cls-out2 {
  display: none;
  width: 180px;
  height: 203px;
  position: relative;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #dce0e4;
  float: right;
  bottom: 140px;
  right: 70px;
}

.cls-img {
  // z-index: 2;
  margin: 10px 16px 0px 16px;
  width: 24px;
  height: 24px;
  position: relative;
}

.cls-img2 {
  margin: 16px 16px 0px 16px;
  width: 148px;
  height: 148px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: relative;
}
.cls-img3 {
  margin: -8px 16px 16px 16px;
  background-image: url("../assets/images/Counseling/vertical_align_top.png");
  width: 24px;
  height: 24px;
  position: relative;
}
.cls-img3:hover {
  background-image: url("../assets/images/Counseling/vertical_align_top(1).png");
}

.cls-text {
  z-index: 20;
  width: 132px;
  height: 13px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #303a50;
  line-height: 13px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.blueBg {
  background: linear-gradient(176deg, #0139d0 0%, #00a3ff 100%);
  color: #ffffff;
}
