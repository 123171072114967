














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.carousel-item {
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
}

@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}

.textColor {
  position: relative;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-image: linear-gradient(
            90deg,
            #03a9f4,
            #ffeb3b,
            #f441a5,
            #03a9f4
    );
    background-clip: text;
    clip-path: ellipse(50px 50px at 0% 50%);
    animation: spotlight 2s infinite;
  }
}

@keyframes spotlight {
  0% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
  50% {
    clip-path: ellipse(50px 50px at 100% 50%);
  }
  100% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
}

.h1 {
  text-align: center;
  // color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.flex-centen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode {
  position: relative;
  overflow: hidden;

  img {
    height: 170px;
    width: 100%;
    display: block;
  }

  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    transition: all 1s;
    color: #fff;
    font-size: 1rem;
  }

  &:hover {
    .description {
      bottom: 0;
    }
  }

  &.last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid;
    color: #409eff;
  }
}

.flexcenten {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .img-fluid{
//   max-width: 100%;
//   height: calc(100% - 1rem);
// }
