/* 全局样式定义 Powered by Zoomla!逐浪CMS z01.com*/
@charset "utf-8";
@import "zoomlaCMS";
// @import url("https://code.z01.com/Zfonts.css");

.maT4 {
	margin-top: 4rem;
}

.xs-0 {
	display: none;
}

.xl-0 {
	display: flex;
	width: auto;
}

.partner {
	.partnerItem {
		overflow: hidden;
		height: 100px;
		background-color: #fff;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		position: relative;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
}

.boxShadow {
	box-shadow: 8px 14px 10px 0 rgba(0, 0, 0, 0.2);
	transition: 1s;

	&:hover {
		box-shadow: 8px 14px 20px 0 rgba(0, 0, 0, 0.2);
		transform: scale(1.1);
	}
}

@media screen and (min-width: 1080px) {
	.xs-0 {
		display: flex;
		width: auto;
	}

	.xl-0 {
		display: none;
	}

	.boss-content {
		max-width: 1080px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 1280px) {
	.inav .logo {
		width: max-content;
		position: absolute;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		margin-top: 8px;
	}

	.inav .nav {
		justify-content: start;
		flex: auto;
		max-width: none;
	}

	.inav .header-right {
		display: none;
	}

	.boss-content {
		max-width: 1280px;
		margin: 0 auto;
	}
}

.services {
	background-color: rgba(216, 216, 216, 0.18);
	padding: 5em 0;
	color: #337ab7;

	h1 {
		color: #000;
		font-weight: 900;
		// font-size: 1.5em;
		// font-family: "Lato", sans-serif;
		text-align: center;
		text-transform: capitalize;
		margin-bottom: 4rem;
		letter-spacing: 2px;
	}

	h3 {
		text-align: center;
		font-size: 1.8rem;
		color: #3acfd5;
	}

	.services_right {
		border-left: 3px solid #3acfd5;

		p {
			text-indent: 2rem;
			font-size: 1.2rem;
			color: #000;
			line-height: 2rem;
			letter-spacing: 2px;
			// font-family: "Open Sans", sans-serif;
			text-align: justify;
		}
	}
}

.ziji {
	margin-bottom: 7rem;

	.cardSelect {
		margin: 2rem 0;
		display: flex;
		justify-content: center;
		font-size: 1.2rem;

		ul {
			padding: 0;

			li {
				margin: 0;
				width: 160px;
				height: 56px;
				background: rgba(0, 0, 0, 0.05);
				color: rgba(0, 0, 0, 0.6);
				text-align: center;
				line-height: 56px;
				cursor: pointer;
				display: inline-block;

				&.active {
					background: rgba(20, 123, 209, 1);
					color: rgba(255, 255, 255, 1);
				}
			}

		}
	}

	.show {
		display: flex;
	}

	.hide {
		display: none;
	}

	.img_content {
		height: 220px;
		background: rgba(249, 249, 249, 1);
		border-radius: 4px;
		display: flex;
		// justify-content: center;
		align-items: center;

		img {
			display: block;
			margin: 0 auto;
			max-width: 220px;
			height: 144px;
			border-radius: 4px;
			transition: all .3s ease;
			// -webkit-filter: grayscale(100%);
			// -moz-filter: grayscale(100%);
			// -ms-filter: grayscale(100%);
			// -o-filter: grayscale(100%);
		}

		&:hover img {
			transform: scale(1.4);
			// -webkit-filter: grayscale(0%);
			// -moz-filter: grayscale(0%);
			// -ms-filter: grayscale(0%);
			// -o-filter: grayscale(0%);
		}
	}

	p {
		margin-top: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.service {
	padding: 5rem 0;

	h1 {
		text-align: center;
		margin-bottom: 4rem;
	}


	.imgShow {
		display: flex;
		padding: 0;
		overflow: hidden;
		height: 450px;

		img {
			display: none;

			&.active {
				display: block;
				height: 100%;
			}
		}
	}

	.tablist {
		height: 450px;
		padding: 0;

		* {
			margin: 0;
			padding: 0;
		}

		ul {
			height: 100%;
		}

		li {
			display: table;
			width: 100%;
			height: 33.4%;
			padding: 26px 32px;
			background: rgba(249, 249, 249, 1);
			cursor: pointer;

			dl {
				display: table-cell;
				vertical-align: middle;
				white-space: nowrap;
				margin: 10px 0;

				dt,
				dd {
					display: inline-block;
					vertical-align: middle;
				}

				.b-icon {
					font-size: 1.4rem;
					font-weight: bolder;
					color: rgba(249, 249, 249, 1);
				}

				dd {
					width: 80%;

					h6 {
						font-size: 1.6rem;
						line-height: inherit;
					}

				}
			}

			&.active {
				background-color: #147BD1;
				color: #fff;

				dl {
					.b-icon {
						color: #fff;
					}
				}

				dd {
					p {
						color: rgba(255, 255, 255, 0.8);
					}

					h6 {
						color: #fff !important;
					}
				}
			}
		}
	}
}

.boxBotron {
	position: relative;
	display: flex;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
		vertical-align: middle;
		border-style: none;
		transition: all .3s;
	}

	&:hover img {
		transform: scale(1.1);
	}

	.boxBotron_content {
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		color: #fff;
		text-align: left;
		padding: 20px;
		z-index: 1;

		h6 {
			font-weight: bold;
			font-size: 1.2rem;
		}

		p {
			font-size: 1rem;
			margin: 0;
		}

		&::before {
			content: "";
			width: 104%;
			height: 104%;
			position: absolute;
			left: -1%;
			top: -1%;
			/* 从父元素继承 background 属性的设置 */
			background: rgba(0, 0, 0, 0.7);
			filter: blur(2px);
			z-index: -1;
		}
	}
}


h1 {
	font-size: 2rem;
}

.mode {
	background-color: #fff;
	line-height: 2rem;
	display: block;
	padding: 10px;
	border-radius: 4px;
	margin-bottom: 2rem;
	box-shadow: 11px 20px 20px 0 rgba(0, 0, 0, 0.2);
	border: 1px solid #E4E7ED;
	color: #000;
	text-decoration: none !important;
	height: 300px;
	width: 100%;

	.img {
		height: 240px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	h2 {
		text-align: center;
		margin-top: 10px;
		font-size: 1.2rem;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	div {
		text-indent: 2rem;
		line-height: 1.9rem;
		text-indent: 2rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 9;
		overflow: hidden;
	}

	&.last {
		line-height: 224px;
		text-align: center;
		border: 1px solid;
		color: #409eff;
	}
}

.hover {
	transition: 1s;

	&:hover {
		transform: scale(1.05);
		box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.3);
	}
}

.inav {
	.navbar-brand img {
		width: auto;
		height: 4rem;
	}

	input {
		width: 8rem !important;
		height: 2.4rem;
	}

	button {
		border: none;
	}

	.nav-item {
		.nav-link {
			color: #fff;
		}

		.nav-link:hover {
			// color: #FF6533;
		}

		// 鼠标导航active
		.router-link-exact-active {
			// color: #FF6533;
		}
	}

	.nav-link:focus {
		// color: #FF6533 !important;
	}

}

.case {
	background-color: #fafafa;
	padding-top: 100px;
	padding-bottom: 100px;
	overflow: hidden;

	h1 {
		text-align: center;
		font-size: 38px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 72px;
	}

	.content {
		background-color: #211f2d;
		border-radius: 13px;
		box-shadow: 6px 2px 23px 0 rgba(0, 0, 0, 0.07);
		text-align: center;
		z-index: 1;
		color: #fff;

		h1 {
			padding: 4rem 2rem 0;
			font-size: 1.2rem;
			font-weight: 800;
			margin-bottom: 2rem;
			text-align: center;
			// text-align: justify;
			color: #fff;
			vertical-align: inherit;
		}

		h2 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 1rem;
			padding-top: 0;
			color: #fff;
		}

		button {
			margin-bottom: 4rem;
			padding: 1rem;
			border: solid 2px #211f2d;
			border-radius: 8px;
			background-image: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
			background-size: 400%;
			color: #fff;
			font-weight: 700;
			text-decoration: none;
		}
	}
}

.liuguang {
	position: relative;
	background-image: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
	background-size: 400%;
	z-index: 1;

	&:hover {
		animation: dropdown 3s infinite;
	}

	&::before {
		content: "";
		position: absolute;
		left: -5px;
		right: -5px;
		top: -5px;
		bottom: -5px;
		border-radius: 8px;
		background-image: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
		background-size: 400%;
		filter: blur(20px);
		z-index: -1;

		&:hover {
			animation: dropdown 3s infinite;
		}
	}
}


@keyframes dropdown {

	100% {
		background-position: -400% 0;
	}
}

.case .content {
	position: relative;

	&::after {
		content: "";
		background: url(https://cdn-m.timecamp.com/img/rebranding/small-circles.svg) center no-repeat;
		width: 12rem;
		height: 12rem;
		position: absolute;
		bottom: -3rem;
		right: -5rem;
	}

	&::before {
		content: "";
		background: url(https://cdn-m.timecamp.com/img/rebranding/small-circles.svg) center no-repeat;
		width: 12rem;
		height: 12rem;
		position: absolute;
		top: -3rem;
		left: -5rem;
	}
}


body {}


.home {
	// background: #F6F6F6;
	// background-image: linear-gradient(48deg, #648ff7, #8660fe)
}

// 首页banner
.ibanner {
	height: 25rem;
	overflow: hidden;
	position: relative;
	font-family: auto;

	&>img {
		position: absolute;
		// top: 50%;
		// left: 50%;
		height: 100%;
		width: 100%;
		transform: translate(-50%, -50%);
	}
}

.box-right {
	text-align: right;
}

.inav .navbar-toggler-icon {
	position: relative;
	background: none;
	cursor: pointer;

	&:after {
		content: "";
		position: absolute;
		top: 7px;
		left: 0px;
		width: 30px;
		height: 1px;
		background-color: #000;
		transition: 1s;
	}

	&:before {
		content: "";
		position: absolute;
		width: 30px;
		bottom: 7px;
		left: 0;
		height: 1px;
		background-color: #000;
		transition: 1s;
	}
}

.inav .not-collapsed {
	.navbar-toggler-icon {
		&::after {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::before {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}


.ibanner_box {
	margin-top: 90px;
	// border: 1.02rem solid rgba(148, 105, 105, 0.47);
	// text-align: center;
	font-family: cursive;
	color: #fff;
	font-size: 1.4rem;
	min-height: 150px;
	font-weight: 700;

	// animation: ibannerBox 5s infinite;
	// animation-direction:alternate;
	ul {
		@include myul;
	}

	li {
		padding: 0.8rem 2rem;
		line-height: 2.3rem;
		display: inline-block;
		margin: 0 10px;

		span {
			color: #E8EA15;
		}

		// &:nth-child(2){background: #c44518;}
		// &:nth-child(3){border-bottom: 1px solid #fff;}
		// i:nth-child(2),i:nth-child(3){margin-left: 1.2rem;}
	}
}

@keyframes ibannerBox {
	from {
		font-size: 1rem;
	}

	to {
		font-size: 2rem;
	}
}

// 首页推荐区
.ielit {
	padding-top: 2.2rem;
	text-align: center;
	// background-color: #1F2431;
	padding-bottom: 3rem;

	.col-md-6 {
		margin-bottom: 1.4rem;
	}

	h1 {
		// color: #fff;
		// font-size: 1.5rem;
		font-weight: bold;
		// font-weight: 900;
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	.text-muted {
		margin-top: 2rem;
		display: block;
		font-size: 1.2rem;
		color: #fff !important;

		p {
			text-indent: 2rem;
			line-height: 2rem;
			text-align: justify;
			background: #fff;
			color: black;
			padding: 18px;
			border-radius: 4px;
			margin: 0;
		}

		// a {
		// 	display: flex;
		// 	position: relative;
		// 	overflow: hidden;
		// 	height: calc(100% - 1rem);
		// 	border-radius: 4px;

		// 	&::after {
		// 		content: "ABOUT US";
		// 		display: flex;
		// 		justify-content: center;
		// 		align-items: center;
		// 		position: absolute;
		// 		bottom: calc(-100%);
		// 		left: 0;
		// 		width: 100%;
		// 		height: 100%;
		// 		font-size: 2rem;
		// 		font-weight: 900;
		// 		color: #fff;
		// 		background-color: rgba(0, 0, 0, 0.3);
		// 		transition: all 1s;
		// 	}

		// 	&:hover {
		// 		&::after {
		// 			bottom: 0;
		// 		}
		// 	}
		// }
	}

	.card-group:nth-child(1) {
		text-align: right;
	}

	.card-group {
		line-height: 1.82rem;
		color: #333;
		font-size: 0.82rem;

		strong {
			display: block;
			width: 100%;
			line-height: 3.4rem;
			font-size: 1.2rem;
		}

		.card {
			min-height: 19rem;
		}

		.card:nth-child(1) {
			padding-top: 4rem;
			text-align: right;
		}

		.card:nth-child(2) {
			// background: url(./assets/images/ielit_bg1.png) center no-repeat;
			background-size: cover;
		}

		.card:nth-child(5) {
			// background: url(./assets/images/ielit_bg2.png) center no-repeat;
			background-size: cover;
		}

		.card:nth-child(7) {
			// background: url(./assets/images/ielit_bg3.png) center no-repeat;
			background-size: cover;
		}

		i {
			font-size: 3rem;
			color: #FCB69F;
		}
	}

}

// 首页立即咨询
.iliji {
	@include dflex;
	margin-top: 2rem;
	height: 10rem;
	color: #fff;
	// background: url(./assets/images/iliji_bg.png) #FF7D52 no-repeat 6rem 3rem;
	border-radius: 6rem;
	text-align: center;
	font-size: 1.52rem;

	button {
		margin: 0.4rem 0.6rem;
		font-size: 2rem;
	}
}

// 首页四大优势
.iyoushi {
	position: relative;
	margin-top: 2rem;
	padding-top: 9.4rem;
	padding-bottom: 7.0rem;
	// background: url(./assets/images/iyoushi_bg.png) no-repeat center;
	background-size: cover;
	font-size: 1.3rem;

	ul {
		@include myul;
		color: #fff;
	}

	li i {
		width: 6rem;
		height: 6rem;
		line-height: 6rem;
		font-size: 3.64rem;
		text-align: center;
		background: #EA68A2;
		border-radius: 5rem;
	}

	p {
		display: inline-block;
	}

	li {
		margin-bottom: 4rem;
	}

	li:nth-child(1) p {
		padding-left: 1rem;
	}

	li:nth-child(3) {
		p {
			padding-left: 1rem;
		}

		i {
			background: #13B5B1;
		}
	}

	li:nth-child(2) {
		text-align: right;

		p {
			padding-right: 1rem;
		}

		i {
			background: #80C269;
		}
	}

	li:nth-child(4) {
		text-align: right;

		p {
			padding-right: 1rem;
		}

		i {
			background: #F8B551;
		}
	}
}

.iyoushi_cneter {
	position: absolute;
	margin-left: 50%;
	left: -10rem;
	top: 8rem;
	width: 20rem;
	height: 20rem;
}

// 首页律师答疑
.iask {
	padding-top: 2rem;
	font-size: 0.92rem;

	h2 {
		color: #EA4C5E;
		text-align: center;
		font-size: 1.98rem;
	}

	small {
		display: block;
		line-height: 3rem;
		text-align: center;
		font-size: 1.02rem;
	}

	.col-md-6:nth-child(1) {
		.iask_boxCon2 {
			margin-bottom: 5rem;
			height: 8rem;
			// background-image: url(./assets/images/iask_bg2.png);
		}
	}


}

.iask_box {
	margin-top: 0.4rem;
	padding: 1rem;
	box-shadow: 0 0 3px #ccc;
	border-radius: 8px;
	background: #fff;
}

.iask_boxCon {
	height: 8rem;

	p {
		display: inline-block;
		float: left;
		width: 6rem;
		text-align: center;
	}

	span {
		display: inline-block;
		padding-left: 2rem;
		width: 19rem;
		height: 3.28rem;
		line-height: 3.28rem;
		color: #fff;
		// background: url(./assets/images/iask_bg1.png) no-repeat center;
	}
}

.iask_boxCon2 {
	padding-top: 1rem;
	padding-left: 1rem;
	height: 12.9rem;
	// background: url(./assets/images/iask_bg3.png) no-repeat left;

	p {
		display: inline-block;
		float: left;
		width: 20.4rem;
		height: 8rem;
	}

	span {
		display: inline-block;
		margin-left: 3rem;
		width: 6rem;
		text-align: center;
	}

	span img {
		width: 100%;
	}
}

// 首页团队
.iteam {
	margin-top: 2rem;
	padding-top: 2rem;
	padding-bottom: 4rem;
	// background: url(./assets/images/iteam_bg.png) center no-repeat;
	background-size: cover;

	h2 {
		text-align: center;
		color: #fff;
		font-size: 1.5rem;
	}

	h2 .text-muted {
		display: block;
		line-height: 3rem;
		font-size: .98rem;
		color: #fff !important;
	}

	.col-md-5 {
		padding-right: 0;
		overflow: hidden;

		&:hover {
			&::before {
				transition-delay: 0s;
				opacity: .58;
				transition: opacity 1s ease .28s;
			}

			.iteam_title {
				opacity: 1;
				color: #EFE448;
			}

			.iteam_cover {
				margin-left: 15px;
				transform-origin: 50% 100%;
				transition-delay: .32s, .22s;
				transform: none;
				transition: opacity .6s ease, transform .86s cubic-bezier(0.19, 1, 0.22, 1);
			}

			img {
				margin-bottom: -1rem;
				transform: rotateX(25deg);
				transition-delay: 0s;
			}
		}

		&:visited img {
			transition-delay: 0s;
			transform: translate3d(0, 10px, 10px) rotate3d(1, 0, 0, 8deg);
		}

		img {
			width: 100%;
			transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) .28s;
		}
	}

	.col-md-7 {
		padding-left: 0;
		padding-top: 3rem;
		height: 23.5rem;
		background: #fff;

		h3 {
			padding-left: 3rem;
			font-size: 1.2rem;
		}

		strong {
			padding-right: 0.6rem;
			color: #f00;
			font-size: 1.8rem
		}

		p {
			padding-left: 3rem;
			color: #333;
		}

		ul {
			@include myul;
			padding-left: 3.0rem;
			font-size: 0.92rem;
			color: #666;
		}

		li {
			line-height: 1.82rem;
		}

		li:before {
			content: "\2022";
			color: #FB8F29;
		}
	}

}

.iteam_cover {
	position: absolute;
	background: #ff6b00;
	transform: rotate3d(1, 0, 0, 8deg) translate3d(0, -110%, -50px);
	opacity: .9;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	z-index: 2;
}

.iteam_title {
	position: absolute;
	top: 11rem;
	left: 2rem;
	font-size: 1.4rem;
	color: #f00;
	transform: translateY(-10px);
	z-index: 2;
}

// 首页案例介绍
.icase {
	padding-top: 3rem;
	// background: url(./assets/images/icase_bg.png) no-repeat center;
	background-size: cover;
	color: #fff;

	h3 {
		text-align: center;
	}

	h3 small {
		display: block;
		line-height: 4rem;
		font-size: 1.02rem;
	}

}

.icase_con {
	padding: 1rem 2rem;
	border: 1px dotted #fff;

	span {
		color: #f00;
	}
}

// 首页
.iming {
	padding-top: 2rem;
	color: #fff;
	text-align: center;

	h3 {
		color: #ea4c19;
	}

	h3 small {
		color: #000;
	}

	small {
		display: block;
		line-height: 3rem;
		font-size: 1.02rem;
	}

	h4 {
		padding-top: 2rem;
	}

}

.iming_r {
	padding-bottom: 1rem;
	background: #FF9656;

	ul {
		@include myul;
		margin: 1rem;
		text-align: left;
		color: #666;
	}

	strong {
		display: block;
		width: 100%;
	}

	li {
		margin-top: 1rem;
		padding: 1rem;
		background: #fff;
	}

	p {
		display: inline-block;
		margin-left: 1rem;
		line-height: 2rem;
	}
}

// 首页
.icg {
	padding-top: 2rem;
	text-align: center;

	span {
		color: #ed4c18;
	}

	h4 {
		margin-bottom: 1rem;
	}
}

// 首页
.ifat {
	padding-top: 2rem;
	// background: url(./assets/images/ifat_bg.png) #fff no-repeat center 9rem;
	height: 30rem;

	h4 {
		text-align: center;
		color: #ed4c18;
	}

	h4 small {
		display: block;
		line-height: 4rem;
		font-size: 1.02rem;
		color: #000;
	}

	ul {
		@include myul;
	}

	strong {
		display: block;
		width: 100%;
	}

	p {
		display: block;
		padding-right: 3rem;
		font-size: 0.92rem;
	}

	img {
		float: left;
		margin-right: 0.2rem;
	}

	li {
		padding-top: 1.62rem;
		height: 10rem;
		// background: url(./assets/images/ifat_bg2.png) no-repeat top left;
	}
}


// 首页
.icruoBox {
	background: #fff;
	border-top: 1px solid #eee;
}

.icruo {
	padding-top: 2rem;
	min-height: 30rem;
	// background: url(./assets/images/icruo_bg.png) no-repeat center;
	background-size: cover;

	h4 {
		text-align: center;
		color: #ed4c18;
	}

	h4 small {
		display: block;
		line-height: 3rem;
		font-size: 1.02rem;
		color: #000;
	}
}

// 首页下方一句标语
.islogan {
	@include dflex;
	min-height: 10rem;
	// background: url(./assets/images/islogan_bg.png) center no-repeat;
	background-size: cover;
	text-align: center;
	color: #fff;
	font-size: 1.4rem;
	font-weight: bold;
}

footer {
	border-top: 2px solid rgba(216, 216, 216, 0.3);
	padding-top: 2rem;
	background: rgba(216, 216, 216, 0.18);
	margin-left: auto;
	position: relative;
	z-index: 999;
	opacity: 0.95;

	dl {
		margin-bottom: 15px;

		dt {
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 12px;
		}

		dd {
			padding: 6px 0;
		}

		dt,
		dd {
			line-height: 1.42857143;
		}
	}

	i {
		font-size: 2.8rem;
	}

	img {
		// height: 6rem;
	}
}







// 关于拓越
.about_banner {
	height: 30rem;
	font-size: 2.9rem;
	color: #fff;
	// background: url(./assets/images/about_banner.png) center no-repeat;
	background-size: cover;
	text-align: center;

	h1 {
		padding-top: 7rem;
		font-size: 1.6rem;
	}
}

.about_con {
	strong {
		display: block;
		margin-top: 1rem;
		line-height: 3rem;
		text-align: center;
		font-size: 1.2rem;
	}

	p {
		color: rgba(0, 0, 0, 0.5);
	}
}


// 联系我们
.contact_banner {
	@extend .about_banner;
	// background-image: url(./assets/images/contact_banner.png);
	background-image: linear-gradient(to left, #4579ff 0%, #4b37c9 100%);
	font-family: cursive;

	h1 {
		padding-top: 8rem;
		font-size: 3rem;
	}

}

.contace_map {
	margin-top: -10rem;
}

.contace_mapBox {
	width: 100%;
	height: 32rem;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;

	.mapText {
		font-size: 1.4rem;
		padding: 12px 20px;
		font-family: cursive;
		// background-color: ;
	}

	.BMapLabel {
		animation: map 1s infinite linear;
		animation-direction: alternate;
		border: 2px solid #4b37c9 !important;
	}
}

@keyframes map {
	0% {
		top: 0;
	}

	100% {
		top: 10px;
	}
}

.contace_put {
	margin-top: 1rem;
	margin-bottom: 1rem;

	input {
		margin-bottom: 1rem;
	}

	.btn-info {
		background-image: linear-gradient(to right, #4579ff 0%, #4b37c9 100%);
	}
}













/*右侧固定在线客服*/
.kefu {
	position: fixed;
	top: 50%;
	right: 20px;
	margin-top: -87px;
	width: 2.3rem;
	text-align: center;

	/*z-index: 1200;*/
	ul {
		@include myul;
	}

	a {
		position: relative;
		float: right;
		margin-top: 0.1rem;
		padding-right: 1.8rem;
		width: 34px;
		height: 34px;
		line-height: 34px;
		background: #7a6e6e;
		color: #fff;
		border-radius: 4px;
		overflow: hidden;
		transition: width 0.5s;
		-moz-transition: width 0.5s;
		-webkit-transition: width 0.5s;
		-o-transition: width 0.5s;
	}

	a:hover {
		background: #c81623;
		width: 100px;
		text-decoration: none;
	}

	a:hover span {
		display: block;
	}

	a:hover i {
		background: #c81623;
	}

	li i {
		display: block;
		position: absolute;
		float: right;
		top: 0;
		right: 0px;
		height: 34px;
		width: 34px;
		line-height: 34px;
		font-size: 20px;
		background: #7a6e6e;
		border-radius: 4px;
		z-index: 10;
	}

	li span {
		font-size: 0.9rem;
	}

	li.kefu_qrcode a {
		overflow: visible;
	}

	li.kefu_qrcode a:hover {
		width: 191px;
	}

	li.kefu_qrcode a:hover span {
		display: block;
	}

	li.kefu_qrcode a span {
		display: none;
		margin-left: 0;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 0;
		background: #c81623;
		border-radius: 4px;
		width: 155px;
	}

	li.kefu_qrcode a span img {
		display: block;
		width: 135px;
	}

	li.kefu_wechat a {
		overflow: visible;
	}

	li.kefu_wechat a:hover {
		width: 191px;
	}

	li.kefu_wechat a:hover span {
		display: block;
	}

	li.kefu_wechat a span {
		display: none;
		margin-left: 0;
		margin-top: -58px;
	}

	li.kefu_wechat a span img {
		padding: 10px;
		background: #c81623;
		border-radius: 4px;
		width: 155px;
	}
}





// 分页器
.home_pagination {
	display: block;
	margin-top: 1rem;
	width: 100%;

	.page-link {
		background-color: #999;
		color: #ccc;
	}

	.page-link:hover {
		background-color: #fff;
	}

	.page-item.disabled .page-link {
		background-color: #999;
	}

	.active .page-link {
		background: #666;
		border-color: #dee2e6;
	}

	.active .page-link:hover {
		background: #fff;
		color: #000;
	}

	.page-item:first-child .page-link {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.page-item:last-child .page-link {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.home_list {
	img {
		width: 100%;
		max-height: 10rem;
	}

	.col-md-8 {
		padding-bottom: 1rem;
		padding-left: 0;
		padding-right: 0;
		border-bottom: 1px solid #2D2D2D;
	}

	.col-md-10 {
		@include 段落文本对齐处理;
		color: #666;
	}

	// .col-md-2{padding-left: 0;}
	strong a {
		color: #fff;
		font-size: 1.24rem;
		font-weight: 100;
		font-family: $苹方极细字体;
	}

	strong a:hvoer {
		text-decoration: none !important;
		color: currentcolor;
	}
}




// 全站页脚
.ifooter {
	text-align: center;
	color: #eee;
	opacity: 0.3;

	a {
		color: #eee;
	}
}

/*针对Markdown的highlight.js之dark主题代码，引用它，同时还要调用highlight和highlightjs-line-numbers更佳，参照z01.com/blog/ */
.hljs-comment,
.hljs-quote {
	color: #7195a8;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
	color: #d22d72;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
	color: #935c25;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
	color: #568c3b;
}

.hljs-title,
.hljs-section {
	color: #257fad;
}

.hljs-keyword,
.hljs-selector-tag {
	color: #6b6bb8;
}

.hljs {
	display: block;
	overflow-x: auto;
	background: #161b1d;
	color: #7ea2b4;
	padding: .5em;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-strong {
	font-weight: 700;
}

.hljs-ln-numbers {
	padding-right: 0.6rem !important;
	border-right: 1px solid #999;
}

.hljs-ln-code {
	margin-left: 0.6rem !important;
	padding-left: 1rem !important;
}

// 内容页定义
.content_bg {
	background: #fff;
}

.myContent {
	h1 {
		font-size: 1.42em;
	}

	h2 {
		font-size: 1.32em;
	}

	h3 {
		font-size: 1.24em;
	}

	img {
		display: block;
		max-width: 100%;
		margin-top: 1rem;
		margin: auto;
		padding: 1rem 2rem;
		border: 0.1rem dashed #9E9E9E;
	}

	blockquote {
		font-size: 1.38rem;
		line-height: 0.98rem;
		color: rgba(0, 0, 0, 0.6);
		font-style: italic;
	}
}

@media (min-width: 768px) {
	.ibanner .offset-md-5 {
		margin-left: 60%
	}


}

@media screen and (max-width:992px) {

	/*小于768px私有*/
	// .navbar-dark .navbar-toggler-icon {
	// 	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	// }

	.inav .navbar-brand img {
		width: auto;
		height: 3rem;
	}

	.carousel-item {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			min-height: 350px;
			width: auto !important;
			max-width: fit-content;
		}
	}

	.carousel {
		margin-top: 54px;
	}

	.ibanner>img {
		width: auto;
	}
	.carousel-indicators {
		display: none;
	}
	.inav {
		background-color: #fff;

		h1 {
			color: #000 !important;
		}

	}

	.ibanner {
		height: 20rem;
		padding-bottom: 1rem;

	}

	.ibanner_box {
		margin-top: 20%;
		// margin-left: 2rem;
		font-size: 1.04rem;
		// color: #fff;

		li {
			line-height: 1.3rem;
			padding: 0.4rem 0.2rem;
		}
	}

	.ielit {
		margin-top: 0.4rem;

		.card-body {
			padding-top: 1rem;
			padding-bottom: 0.2rem;
		}

		.card-group .card {
			min-height: 15rem;
		}

		.card-group .card:nth-child(1) {
			padding-top: 0.2rem;
		}
	}

}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}


.caseVue {
	.header {
		padding-top: 90px;
		height: 25rem;
		min-height: 98px;
		background: linear-gradient(90deg, #2839a0 0, #7b5ab6 100%), #2d5fcd;
		text-align: center;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&>h1 {
			// padding-top: 8rem;
			font-family: auto;
		}

		&>h3 {
			// line-height: 4rem;
		}
	}

	main {
		// margin-top: -8rem;
	}
}

/*key:UG93ZXJlZCBieSBab29tbGEh6YCQ5rWqQ01TIHd3d3cuejAxLmNvbQ== */